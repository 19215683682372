/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "@ionic/angular/css/ionic-swiper";
@import "swiper/swiper-bundle.css";

* {
  @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;1,200&display=swap");
}

ion-card-title {
  color: #4e4e4e;
  font-weight: bold;
}

ion-content {
  --background: #f2f2f2;
}

h2 {
  color: #4e4e4e;
  font-weight: bold;
}

h3 {
  color: #4e4e4e;
  font-weight: bold;
}

.bold {
  font-weight: bold !important;
}

.white {
  color: white !important;
}

.main-card {
  position: relative;
  bottom: 35px;
  border-radius: 20px;
  margin: 0 15px;
  background-image: linear-gradient(
    to top,
    #f2f2f2,
    #f5f5f5,
    #f8f8f8,
    #fcfcfc,
    #ffffff
  );
  box-shadow: none;
}

.center {
  text-align: center;
  margin: 0 auto;
}

.secondary-card {
  border-radius: 20px;
  padding: 15px;
  margin: 20px 15px !important;
  background-color: #ffffff;
  box-shadow: none;

  h2 {
    font-size: 12px;
  }
}

.item-ios {
  background: transparent;
}

.center-desktop {
  display: none;
  display: flex;
  justify-content: center;
}

.close {
  cursor: pointer;
}

.longModal {
  --height: 99vh;
}

.cursor {
  cursor: pointer;
}

.modalOrderDetail {
  --width: 1024px !important;
  --height: 650px !important;
  --border-radius: 15px !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

.modalOrderTracking {
  --width: 1024px !important;
  --height: 650px !important;
  --border-radius: 15px !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

.productModal {
  --border-radius: 15px;
}

.modalLogin {
  --min-width: 65vw;
  &::part(content) {
    border-radius: 20px;
  }
}

.cupomModal {
  --border-radius: 15px;
}

.modal-shopping-cart {
  --border-radius: 15px;
}

.modalNewAddress {
  --min-width: 65vw;
  &::part(content) {
    border-radius: 20px;
  }
}

ion-modal.address-modal {
  --min-width: 65vw;
  --border-radius: 15px;
}

.modalCC{
  --border-radius: 15px;
}
.modalPayment {
  --border-radius: 15px;
}

@media screen and (min-width: 1024px) {
  .main-card {
    background: #ffffff !important;
    margin: 0 200px !important;
  }

  ion-content {
    --background: #ffffff !important;
  }
}

@media screen and (max-width: 768px) {
  .cupomModal {
    --border-radius: 0px;
  }
  .productModal {
    --border-radius: 0;
  }
  .modalCC{
    --border-radius: 0px;
  }

  ion-modal.address-modal {
    --min-width: 65vw;
    --border-radius: 0px;
  }

  .modalPayment {
    --border-radius: 0px;
  }
  .modal-shopping-cart {
    --border-radius: 0px;
  }

  .longModal {
    --height: 100%;
  }
  .modalPayment {
    --border-radius: 0px;
  }

  .modalConfirm {
    --height: 40vh;
  }
}
